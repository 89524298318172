.card-wrapper {
  width: 30em;
  padding: .25rem;
  margin: .25rem;
  border-radius: .25rem;
}

.card-body {
  background-color: #131516 !important;
  color: #d8d4cf !important;
}

.text-muted {
  color: rgb(158, 150, 137) !important;
}

.card-img-top {
  max-width: 100%;
  max-height: 250px;
  overflow: hidden;
  object-fit: scale-down;
  background-color: black;
}

.hover-zoom {
  transition: all 0.1s ease 0s;
  width: 100%;
}
.hover-zoom:hover {
  transform: scale(1.05);
}

.row.limit [class*="col-"]{
  max-height:50px;
  overflow:hidden;
}
